import { render, staticRenderFns } from "./UserRoleModal.vue?vue&type=template&id=64670ede&scoped=true&"
import script from "./UserRoleModal.vue?vue&type=script&lang=js&"
export * from "./UserRoleModal.vue?vue&type=script&lang=js&"
import style0 from "./UserRoleModal.vue?vue&type=style&index=0&id=64670ede&lang=less&scoped=true&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "64670ede",
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("/home/admin/ylpp-admin/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('64670ede')) {
      api.createRecord('64670ede', component.options)
    } else {
      api.reload('64670ede', component.options)
    }
    module.hot.accept("./UserRoleModal.vue?vue&type=template&id=64670ede&scoped=true&", function () {
      api.rerender('64670ede', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/views/system/modules/UserRoleModal.vue"
export default component.exports