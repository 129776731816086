var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.syncToApp && _vm.syncToLocal
    ? _c(
        "a-dropdown",
        [
          _c("a-button", { attrs: { type: "primary", icon: "sync" } }, [
            _vm._v("同步" + _vm._s(_vm.name))
          ]),
          _c(
            "a-menu",
            {
              attrs: { slot: "overlay" },
              on: { click: _vm.handleMenuClick },
              slot: "overlay"
            },
            [
              _vm.syncToApp
                ? _c("a-menu-item", { key: "to-app" }, [
                    _vm._v("同步到" + _vm._s(_vm.name))
                  ])
                : _vm._e(),
              _vm.syncToLocal
                ? _c("a-menu-item", { key: "to-local" }, [_vm._v("同步到本地")])
                : _vm._e()
            ],
            1
          )
        ],
        1
      )
    : _vm.syncToApp
    ? _c(
        "a-button",
        {
          attrs: { type: "primary", icon: "sync" },
          on: {
            click: function($event) {
              return _vm.handleMenuClick({ key: "to-app" })
            }
          }
        },
        [_vm._v("同步" + _vm._s(_vm.name))]
      )
    : _c(
        "a-button",
        {
          attrs: { type: "primary", icon: "sync" },
          on: {
            click: function($event) {
              return _vm.handleMenuClick({ key: "to-local" })
            }
          }
        },
        [_vm._v("同步" + _vm._s(_vm.name) + "到本地")]
      )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }