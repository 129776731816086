var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.syncToApp || _vm.syncToLocal
    ? _c(
        "span",
        [
          _vm.enabledTypes.wechatEnterprise
            ? _c(
                "j-third-app-dropdown",
                _vm._g(
                  _vm._b(
                    { attrs: { type: "wechatEnterprise", name: "企微" } },
                    "j-third-app-dropdown",
                    _vm.bindAttrs,
                    false
                  ),
                  _vm.bindEvents
                )
              )
            : _vm._e(),
          _vm.enabledTypes.dingtalk
            ? _c(
                "j-third-app-dropdown",
                _vm._g(
                  _vm._b(
                    { attrs: { type: "dingtalk", name: "钉钉" } },
                    "j-third-app-dropdown",
                    _vm.bindAttrs,
                    false
                  ),
                  _vm.bindEvents
                )
              )
            : _vm._e()
        ],
        1
      )
    : _c("span", [_vm._v("未设置任何同步方向")])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }