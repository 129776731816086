var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "a-modal",
    {
      attrs: {
        title: _vm.title,
        width: 800,
        visible: _vm.visible,
        confirmLoading: _vm.confirmLoading,
        cancelText: "关闭"
      },
      on: { ok: _vm.handleOk, cancel: _vm.handleCancel }
    },
    [
      _c(
        "a-spin",
        { attrs: { spinning: _vm.confirmLoading } },
        [
          _c(
            "a-form",
            [
              _c(
                "a-form-item",
                {
                  attrs: {
                    labelCol: _vm.labelCol,
                    wrapperCol: _vm.wrapperCol,
                    label: "模板标题"
                  }
                },
                [
                  _c("a-input", {
                    attrs: { disabled: "" },
                    model: {
                      value: _vm.templateName,
                      callback: function($$v) {
                        _vm.templateName = $$v
                      },
                      expression: "templateName"
                    }
                  })
                ],
                1
              ),
              _c(
                "a-form-item",
                {
                  attrs: {
                    labelCol: _vm.labelCol,
                    wrapperCol: _vm.wrapperCol,
                    label: "模板内容"
                  }
                },
                [
                  _c("a-textarea", {
                    attrs: {
                      disabled: "",
                      autosize: { minRows: 5, maxRows: 8 }
                    },
                    model: {
                      value: _vm.templateContent,
                      callback: function($$v) {
                        _vm.templateContent = $$v
                      },
                      expression: "templateContent"
                    }
                  })
                ],
                1
              ),
              _c(
                "a-form-item",
                {
                  attrs: {
                    labelCol: _vm.labelCol,
                    wrapperCol: _vm.wrapperCol,
                    label: "测试数据"
                  }
                },
                [
                  _c("a-textarea", {
                    attrs: {
                      placeholder: "请输入json格式测试数据",
                      autosize: { minRows: 5, maxRows: 8 }
                    },
                    model: {
                      value: _vm.testData,
                      callback: function($$v) {
                        _vm.testData = $$v
                      },
                      expression: "testData"
                    }
                  })
                ],
                1
              ),
              _c(
                "a-form-item",
                {
                  attrs: {
                    labelCol: _vm.labelCol,
                    wrapperCol: _vm.wrapperCol,
                    label: "消息类型"
                  }
                },
                [
                  _c("j-dict-select-tag", {
                    attrs: {
                      placeholder: "请选择消息类型",
                      dictCode: "msgType"
                    },
                    model: {
                      value: _vm.msgType,
                      callback: function($$v) {
                        _vm.msgType = $$v
                      },
                      expression: "msgType"
                    }
                  })
                ],
                1
              ),
              _c(
                "a-form-item",
                {
                  attrs: {
                    labelCol: _vm.labelCol,
                    wrapperCol: _vm.wrapperCol,
                    label: "消息接收方"
                  }
                },
                [
                  _c("a-input", {
                    attrs: { placeholder: "请输入消息接收方" },
                    model: {
                      value: _vm.receiver,
                      callback: function($$v) {
                        _vm.receiver = $$v
                      },
                      expression: "receiver"
                    }
                  })
                ],
                1
              )
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }